<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 bookings-screen full-width gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          services
        </div>
        <template v-slot:right>
          <router-link
            :to="{
              name: 'r_services-resources'
            }"
          >
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
    </template>
    <div class="service-options">
      <div
        class="today-div"
        ref="today"
        :style="[this.today ? 'color: lightgray;' : 'color: #24b7af']"
        @click="setToday()"
      >
        {{ displayLabelName("services.services.today") }}
      </div>
      <div>
        <span
          class="service-selected-date"
          @click="showCalendarDatePicker = !showCalendarDatePicker"
        >
          {{ displayDate(selectedDateFromParsed) }} -
          {{ displayDate(selectedDateToParsed) }}
        </span>
        <div class="service-calendar-wrapper" v-if="showCalendarDatePicker">
          <select-date-range
            :selectDateFn="selectCalendarDate"
            :show-date-picker="showCalendarDatePicker"
            :dates-to-watch="[selectedDateFrom, selectedDateTo]"
            :selected-dates="[selectedDateFrom, selectedDateTo]"
          />
        </div>
      </div>

      <search
        @clear="searchQuery = ''"
        :hasFilters="false"
        :simpleSearch="true"
        @keyup="searchQuery = $event.target.value"
      />
    </div>
    <div class="bookings-body" style="height: auto;">
      <ul class="clebex-item-section pill bookings-header">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper grey-bg">
            <!-- order id -->
            <span
              class="label"
              style="flex: 0; min-width: 50px !important;"
              @click="sortServices('order_id')"
            >
              <span class="highlight">#</span>
            </span>
            <!-- delivery point -->
            <span class="label" @click="sortServices('delivery_point')">
              <span class="highlight">{{
                displayLabelName("services.services.resource")
              }}</span>
            </span>
            <!-- service item -->
            <span class="label" @click="sortServices('service_item')">
              <span class="highlight">{{
                displayLabelName("services.services.service-item")
              }}</span>
            </span>
            <!-- quantity -->
            <span
              class="label"
              style="flex: 0; min-width: 50px !important;"
              @click="sortServices('quantity')"
            >
              <span class="highlight">{{
                displayLabelName("services.services.quantity-short")
              }}</span>
            </span>
            <!-- request by -->
            <span class="label" @click="sortServices('request_by')">
              <span class="highlight">{{
                displayLabelName("services.services.request-by")
              }}</span>
            </span>
            <!-- date time -->
            <span class="label" @click="sortServices('delivery_period')">
              <span class="highlight">{{
                displayLabelName("services.services.date-time")
              }}</span>
            </span>
            <!-- quantity -->
            <span class="label" @click="sortServices('note')">
              <span class="highlight">{{
                displayLabelName("services.services.note")
              }}</span>
            </span>
            <!-- price -->
            <span class="label" @click="sortServices('price')">
              <span class="highlight">{{
                displayLabelName("services.services.price")
              }}</span>
            </span>
            <!-- status -->
            <span class="label" @click="sortServices('service_status')">
              <span class="highlight">{{
                displayLabelName("services.services.status")
              }}</span>
            </span>
          </div>
        </li>
      </ul>
      <!-- Items -->
      <ul
        class="clebex-item-section pill mt-0 mb-0"
        style="overflow: auto;"
        ref="servicesbody"
        id="servicesbody"
      >
        <li
          v-for="(serviceOrder, i) in listOfServiceOrders"
          :key="i"
          class="clebex-item-section-item row"
          style="cursor: pointer;"
        >
          <p
            v-if="serviceOrder.group_by && serviceOrder.group_by != 'none'"
            class="group-by"
          >
            {{ serviceOrder.group_by }}
          </p>
          <div class="clebex-item-content-wrapper">
            <div
              class="booking-item-column"
              @click="clickAction(serviceOrder)"
              style="flex: 0; min-width: 50px !important;"
            >
              <span class="column-name"># </span>
              <span
                class="highlight"
                :style="[
                  serviceOrder.deleted_at
                    ? 'text-decoration: line-through; color: gray;'
                    : ''
                ]"
                >{{ serviceOrder.order_id }}</span
              >
            </div>
            <div class="booking-item-column" @click="clickAction(serviceOrder)">
              <span class="column-name"
                >{{ displayLabelName("services.services.resource") }}:
              </span>
              <span
                class="highlight"
                :style="[
                  serviceOrder.deleted_at
                    ? 'text-decoration: line-through; color: gray;'
                    : ''
                ]"
                >{{ serviceOrder.delivery_point }}</span
              >
            </div>
            <div class="booking-item-column" @click="clickAction(serviceOrder)">
              <span class="column-name"
                >{{ displayLabelName("services.services.service-item") }}:
              </span>
              <span
                class="highlight"
                :style="[
                  serviceOrder.deleted_at
                    ? 'text-decoration: line-through; color: gray;'
                    : ''
                ]"
                >{{ serviceOrder.service_item }}
              </span>
            </div>
            <div
              class="booking-item-column"
              @click="clickAction(serviceOrder)"
              style="flex: 0; min-width: 50px !important;"
            >
              <span class="column-name"
                >{{ displayLabelName("services.services.quantity-short") }}:
              </span>
              <span
                class="highlight"
                :style="[
                  serviceOrder.deleted_at
                    ? 'text-decoration: line-through; color: gray;'
                    : ''
                ]"
                >{{ serviceOrder.quantity }}</span
              >
            </div>
            <div class="booking-item-column" @click="clickAction(serviceOrder)">
              <span class="column-name"
                >{{ displayLabelName("services.services.request-by") }}:
              </span>
              <span
                class="highlight"
                :style="[
                  serviceOrder.deleted_at
                    ? 'text-decoration: line-through; color: gray;'
                    : ''
                ]"
                >{{ serviceOrder.request_by }}</span
              >
            </div>
            <div class="booking-item-column" @click="clickAction(serviceOrder)">
              <span class="column-name"
                >{{ displayLabelName("services.services.date-time") }}:
              </span>
              <span
                class="highlight"
                :style="[
                  serviceOrder.deleted_at
                    ? 'text-decoration: line-through; color: gray;'
                    : ''
                ]"
                >{{ serviceOrder.delivery_period }}</span
              >
            </div>
            <div class="booking-item-column" @click="clickAction(serviceOrder)">
              <span class="column-name"
                >{{ displayLabelName("services.services.note") }}:
              </span>
              <span
                class="highlight"
                :style="[
                  serviceOrder.deleted_at
                    ? 'text-decoration: line-through; color: gray;'
                    : ''
                ]"
                >{{ serviceOrder.note }}</span
              >
            </div>
            <div class="booking-item-column" @click="clickAction(serviceOrder)">
              <span class="column-name"
                >{{ displayLabelName("services.services.price") }}:
              </span>
              <span
                class="highlight"
                :style="[
                  serviceOrder.deleted_at
                    ? 'text-decoration: line-through; color: gray;'
                    : ''
                ]"
                >{{ serviceOrder.price }} {{ serviceOrder.currency }}</span
              >
            </div>
            <div
              class="booking-item-column"
              @click="clickAction(serviceOrder, 1)"
            >
              <span class="column-name"
                >{{ displayLabelName("services.services.status") }}:
              </span>
              <span
                class="highlight"
                :style="[
                  serviceOrder.deleted_at
                    ? 'text-decoration: line-through; color: gray;'
                    : ''
                ]"
                >{{ serviceOrder.service_status }}</span
              >
            </div>
          </div>
        </li>
      </ul>
    </div>
    <slider-modal
      v-if="showSliderModal && modalData.length"
      :show="showSliderModal"
      :data="modalData"
      confirm-button-label="Close"
      :confirm-action="cancelStatusDialog"
      @close="cancelStatusDialog"
    >
      <template v-slot="{ data }">
        <slider-modal-content :data="data"></slider-modal-content>
      </template>
    </slider-modal>
    <template v-slot:footer>
      <section class="clean-footer-menu icon-foo1">
        <!-- <span class="services-select">Select</span> -->
        <nav class="menu">
          <ul
            class="menu-items"
            style="align-items: center; justify-content: center; gap: 5%;"
          >
            <li
              class="menu-item meeting"
              style="min-width: 3.125rem; width: auto;"
              @click="groupServices('delivery_point')"
            >
              <button>
                <icon icon="#cx-vis1-meeting" />
                {{ displayLabelName("services.services.resource") }}
              </button>
            </li>
            <li class="menu-item central-item icon-foo2">
              <qr-code-scanner-button />
            </li>
            <li
              class="menu-item company"
              style="min-width: 3.125rem; width: auto;"
              @click="groupServices('order_id')"
            >
              <button>
                <icon icon="#cx-men1-services" />
                {{ displayLabelName("services.services.order") }}
              </button>
            </li>
          </ul>
        </nav>
      </section>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import { DateTime } from "luxon";
import { formatDate } from "@/services/helpers";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import QrCodeScannerButton from "@/components/global/QrCodeScannerButton";
import { mapState, mapActions, mapGetters } from "vuex";
import { subscribe, unsubscribe } from "@/services/ws-service";
import { wsEvents } from "@/services/constants";
import Search from "@/components/search/Search";
import { getSubdomain } from "@/services/http-service";

export default {
  name: "Services",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    QrCodeScannerButton,
    Search,
    SliderModal: defineAsyncComponent(() =>
      import("@/components/global/SliderModal")
    ),
    SliderModalContent: defineAsyncComponent(() =>
      import("@/components/bookings/SliderModalContent")
    ),
    SelectDateRange: defineAsyncComponent(() =>
      import("@/components/global/SelectDateRange")
    )
  },
  data() {
    return {
      listView: true,
      showSliderModal: false,
      search: false,
      resize: false,
      today: false,
      searchQuery: "",
      searchContent: "",
      groupBy: "none",
      channel: null,
      sortBy: "order_id",
      sortOrder: "asc",
      showCalendarDatePicker: false,
      selectedDateFrom: DateTime.now()
        .plus({ days: -15 })
        .toFormat("yyyy-dd-MM"),
      selectedDateTo: DateTime.now()
        .plus({ days: 15 })
        .toFormat("yyyy-dd-MM"),
      selectedDateFromParsed: DateTime.now()
        .plus({ days: -15 })
        .toFormat("yyyy-MM-dd"),
      selectedDateToParsed: DateTime.now()
        .plus({ days: 15 })
        .toFormat("yyyy-MM-dd"),
      selectedDateEventFrom: DateTime.now()
        .plus({ days: -15 })
        .toFormat("yyyy-MM-dd"),
      selectedDateEventTo: DateTime.now()
        .plus({ days: 15 })
        .toFormat("yyyy-MM-dd")
    };
  },
  created() {
    this.setSearchQuery(null);
    this.setInitialValues();
    if (!this.resize) {
      window.addEventListener("resize", () => {
        this.setDisplaySize();
      });
      this.resize = true;
    }
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("service", ["serviceOrders"]),
    routeName() {
      return this.$route.name;
    },
    listOfServiceOrders() {
      const query = this.searchQuery;
      if (
        this.serviceOrders &&
        this.serviceOrders.data &&
        this.serviceOrders.data.length
      ) {
        if (query && query.length > 1) {
          return this.serviceOrders.data.filter(
            item =>
              item.delivery_point.toLowerCase().includes(query.toLowerCase()) ||
              String(item.order_id)
                .toLowerCase()
                .includes(query.toLowerCase()) ||
              item.request_by.toLowerCase().includes(query.toLowerCase()) ||
              item.currency.toLowerCase().includes(query.toLowerCase()) ||
              item.delivery_period
                .toLowerCase()
                .includes(query.toLowerCase()) ||
              item.service_item.toLowerCase().includes(query.toLowerCase()) ||
              item.note.toLowerCase().includes(query.toLowerCase()) ||
              item.service_status.toLowerCase().includes(query.toLowerCase()) ||
              String(item.quantity)
                .toLowerCase()
                .includes(query.toLowerCase()) ||
              String(item.price)
                .toLowerCase()
                .includes(query.toLowerCase())
          );
        }
        return this.serviceOrders.data;
      }
      return [];
    }
  },
  methods: {
    ...mapActions("service", [
      "getServiceOrders",
      "setServiceOrder",
      "setServiceResource",
      "setFilterDateFrom",
      "setFilterDateTo",
      "setFilterSortBy",
      "setFilterSortOrder",
      "setFilterGroupBy"
    ]),
    ...mapActions("search", ["setSearchQuery"]),
    setToday() {
      if (this.today == false) {
        this.selectedDateFrom = DateTime.now().toFormat("yyyy-dd-MM");
        this.selectedDateFromParsed = DateTime.now().toFormat("yyyy-MM-dd");
        this.selectedDateEventFrom = DateTime.now().toFormat("yyyy-MM-dd");
        this.setFilterDateFrom(this.selectedDateEventFrom);

        this.selectedDateTo = DateTime.now().toFormat("yyyy-dd-MM");
        this.selectedDateToParsed = DateTime.now().toFormat("yyyy-MM-dd");
        this.selectedDateEventTo = DateTime.now().toFormat("yyyy-MM-dd");
        this.setFilterDateTo(this.selectedDateEventTo);
        this.today = true;
      } else {
        this.selectedDateFrom = DateTime.now()
          .plus({ days: -15 })
          .toFormat("yyyy-dd-MM");
        this.selectedDateFromParsed = DateTime.now()
          .plus({ days: -15 })
          .toFormat("yyyy-MM-dd");
        this.selectedDateEventFrom = DateTime.now()
          .plus({ days: -15 })
          .toFormat("yyyy-MM-dd");
        this.setFilterDateFrom(this.selectedDateEventFrom);

        this.selectedDateTo = DateTime.now()
          .plus({ days: 15 })
          .toFormat("yyyy-dd-MM");
        this.selectedDateToParsed = DateTime.now()
          .plus({ days: 15 })
          .toFormat("yyyy-MM-dd");
        this.selectedDateEventTo = DateTime.now()
          .plus({ days: 15 })
          .toFormat("yyyy-MM-dd");
        this.setFilterDateTo(this.selectedDateEventTo);
        this.today = false;
      }
      this.getServiceOrders();
    },
    setDisplaySize() {
      if (this.$refs.servicesbody) {
        if (this.$refs.servicesbody.clientWidth > 1015) {
          this.$refs.servicesbody.style.height =
            window.innerHeight - 360 + "px";
        } else {
          this.$refs.servicesbody.style.height =
            window.innerHeight - 450 + "px";
        }
      }
    },
    selectCalendarDate(dates) {
      if (!dates[0]) {
        this.selectedDateFrom = this.selectedDateFromParsed = this.selectedDateEventFrom = null;
        return;
      }
      this.selectedDateFrom = DateTime.fromISO(dates[0].eventDate).toFormat(
        "yyyy-dd-MM"
      );
      this.selectedDateFromParsed = dates[0].eventDate;
      this.selectedDateEventFrom = dates[0].eventDate;

      this.setFilterDateFrom(this.selectedDateEventFrom);

      /**
       * Wait for second date to be set and then we may make call
       */
      if (!dates[1]) {
        this.selectedDateTo = this.selectedDateToParsed = this.selectedDateEventTo = null;
        return;
      }

      this.selectedDateTo = DateTime.fromISO(dates[1].eventDate).toFormat(
        "yyyy-dd-MM"
      );
      this.selectedDateToParsed = dates[1].eventDate;
      this.selectedDateEventTo = dates[1].eventDate;

      this.setFilterDateTo(this.selectedDateEventTo);

      this.$router.push({ name: this.routeReceptionDesk });
      this.getServiceOrders().then(() => {
        this.showCalendarDatePicker = false;
      });
    },
    displayDate(date) {
      if (!date) {
        return this.displayLabelName("global.calendar.select-date");
      }
      return formatDate(date, this.globalDateFormat, true);
    },
    subscribe() {
      this.channel = subscribe(
        "service-orders",
        [getSubdomain()],
        wsEvents.serviceOrders.changed,
        this.wsCallback,
        true
      );
    },
    wsCallback() {
      this.getServiceOrders();
    },
    sortServices(sortBy) {
      if (sortBy == this.sortBy) {
        if (this.sortOrder == "asc") {
          this.sortOrder = "desc";
        } else {
          this.sortOrder = "asc";
        }
      } else {
        this.sortOrder = "asc";
        this.sortBy = sortBy;
      }

      this.setFilterSortBy(this.sortBy);
      this.setFilterSortOrder(this.sortOrder);

      this.getServiceOrders();
    },
    clickAction(serviceOrder, status = null) {
      if (
        this.$store._state.data.user.userData.data.id == serviceOrder.user_id &&
        status == null
      ) {
        this.openOrder(serviceOrder);
      } else if (
        serviceOrder.update_status &&
        this.$store._state.data.user.userData.data.id !== serviceOrder.user_id
      ) {
        this.updateStatus(serviceOrder);
      } else if (serviceOrder.update_status && status != null) {
        this.updateStatus(serviceOrder);
      }
    },
    searchAction() {
      if (this.search == false) {
        this.search = true;
        // this.$refs.search.focus();
        this.$nextTick(() => {
          this.$refs.search.focus();
        });
      } else {
        this.search = false;
        this.searchQuery = "";
      }
    },
    openOrder(serviceOrder) {
      this.setServiceResource(serviceOrder.resource_id);
      this.$router.push({
        name: "r_services-edit-order",
        params: { service_order_id: serviceOrder.order_id }
      });
    },
    updateStatus(serviceOrder) {
      this.$router.push({
        name: "r_services-update-order-status",
        params: {
          service_order_id: serviceOrder.order_id
        }
      });
    },
    setInitialValues() {
      this.$store.commit("global/setIsFullWidthScreen", true, {
        root: true
      });
      this.getServiceOrders();
      this.subscribe();
    },
    cancelStatusDialog() {
      this.showSliderModal = false;
    },
    inputChanged(val) {
      this.searchQuery = val;
    },
    clearSearch() {
      this.searchQuery = "";
      this.searchContent = "";
    },
    lostFocus() {
      setTimeout(() => {
        this.search = false;
      }, 100);
    },
    groupServices(groupBy) {
      if (this.groupBy == groupBy) {
        this.groupBy = "none";
      } else {
        this.groupBy = groupBy;
      }

      this.setFilterGroupBy(this.groupBy);
      this.getServiceOrders();
    }
  },
  watch: {
    serviceOrders() {
      this.$nextTick(function() {
        this.setDisplaySize();
      });
    },
    routeName() {
      this.$nextTick(function() {
        this.setDisplaySize();
      });
    }
  },
  beforeUnmount() {
    this.$store.commit("global/setIsFullWidthScreen", false, {
      root: true
    });
    if (this.channel) {
      unsubscribe(this.channel);
      this.channel = null;
    }
  }
};
</script>
<style>
.deleted-count {
  position: relative;
  top: -4px;
  margin: 5px 8px;
  padding-left: 0px !important;
  margin-left: 0px;
}
.path {
  color: rgb(163, 163, 163);
}
.group-by {
  color: rgb(97, 97, 97);
  height: 50px;
  align-items: center;
  display: flex;
  padding-left: 10px;
}
.services-select {
  color: #999999;
  position: absolute;
  left: 10px;
  bottom: 40px;
  cursor: pointer;
}
.bookings-body {
  border: 0px !important;
}
</style>
